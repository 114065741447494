<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="委託契約書一覧" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />

      <!-- 検索フォーム ============================================================ -->
      <SearchForm
        @search="getContractSearchApi"
      />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <div v-if="entrustList.length" class="l-flex cols l-item">
          <div class="u-txt-bold">選択中 {{ checkCount }} 件</div>
          <div class="l-flex cols">
            <template v-if="!isCollector && !isDisposer">
              <div class="c-btn secondary small" :class="{ disabled: !isInvalidBtnActive }" @click="clickUpdateEntrustHandler(false)">
                無効化
              </div>
              <div class="c-btn primary small" :class="{ disabled: !isValidBtnActive }" @click="clickUpdateEntrustHandler(true)">
                有効化
              </div>
            </template>
            <div class="c-btn primary small" :class="{ disabled: isDisableEntrustDelivery }" @click="clickDownloadHandler">受渡確認票印刷</div>
          </div>
        </div>

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell cb">
                <div class="c-checkbox">
                  <input
                      id="check-all-entrusts"
                      type="checkbox"
                      v-model="isAllChecked"
                      @change="changeAllCheckHandler"
                  />
                  <label class="c-checkbox__label" for="check-all-entrusts">
                    <span class="c-checkbox__box"></span>
                  </label>
                </div>
              </div>
              <div class="c-infoList__cell sm">委託契約書番号</div>
              <div class="c-infoList__cell md">
                <a @click="clickSortChangeHandler">
                    <span
                        class="c-infoList__cell__sort"
                        :class="{
                        asc: sortParams.type.toLowerCase() === 'asc',
                        desc: sortParams.type.toLowerCase() === 'desc',
                      }"
                    >排出事業者</span>
                </a>
              </div>
              <div class="c-infoList__cell md">排出事業場</div>
              <div class="c-infoList__cell sm">廃棄物区分</div>
              <div class="c-infoList__cell md">廃棄物名称</div>
              <div v-if="!isCollector" class="c-infoList__cell md">運搬事業者</div>
              <div v-if="!isDisposer" class="c-infoList__cell md">処分事業者</div>
              <div class="c-infoList__cell sm">ステータス</div>
              <div class="c-infoList__cell sm">有効開始日</div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="entrustList.length">
              <div
                  v-for="(entrust, index) of entrustList"
                  :key="'entrust-' + index"
                  class="c-infoList__row list"
                  :class="{'notActive': !entrust.isActive || entrust.contractEndLimit === limitStatus.DANGER}"
              >
                <div class="c-infoList__cell cb text-dotted">
                  <div class="c-checkbox">
                    <input
                        :id="'cb-' + index"
                        type="checkbox"
                        v-model="entrust.isChecked"
                        @change="changeEntrustCbHandler(entrust.isChecked)"
                    />
                    <label class="c-checkbox__label" :for="'cb-' + index">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>

                <!-- 排出事業者 -->
                <template v-if="entrust.entrustId">
                  <div class="c-infoList__cell sm c-tooltip">
                    {{ entrust.entrustId }}
                  </div>
                </template>
                <template v-if="entrust.contractEndLimit">
                  <div class="c-infoList__cell md c-tooltip">
                    <div class="c-infoText">
                      <div>
                        <router-link @click.native="setEntrustPage()" :to="`/entrust/${entrust.entrustId}`">
                          {{ entrust.generataCompanyInfo.name }}
                        </router-link>
                      </div>
                    </div>

                    <div class="c-status" :class="entrust.contractEndLimit === 'warning' ? 'info-yellow' : 'info-red'">
                      <div class="c-infoList__popup">
                        <span v-for="(msg, msgIndex) of entrust.msgs" :key="'msg-' + msgIndex">{{ msg }}<br></span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="c-infoList__cell md">
                    <router-link @click.native="setEntrustPage()" :to="`/entrust/${entrust.entrustId}`">
                      {{ entrust.generataCompanyInfo.name }}
                    </router-link>
                  </div>
                </template>

                <!-- 排出事業場 -->
                <div class="c-infoList__cell md c-tooltip">
                  <span v-if="!entrust.generateStoreList || !entrust.generateStoreList.length"></span>
                  <CommaText :items="entrust.generateStoreList" arg="name" :showblankText="true" />
                </div>

                <!-- 廃棄物区分 -->
                <!-- <div v-else class="c-infoList__cell md c-tooltip"> -->
                <div class="c-infoList__cell sm">
                  {{ entrust.wasteType ? entrust.wasteType.name : "-" }}
                </div>

                <!-- 廃棄物名称 -->
                <template v-if="entrust.wasteNameList && entrust.wasteNameList.length">
                  <div class="c-infoList__cell md c-tooltip">
                    <CommaText :items="entrust.wasteNameList" arg="name" />
                  </div>
                </template>
                <template v-else>
                  <div class="c-infoList__cell md">-</div>
                </template>

                <!-- 運搬事業者 -->
                <div v-if="!isCollector" class="c-infoList__cell md c-tooltip">
                  <CommaText :items="entrust.collectCompanyList" arg="name" :showblankText="true" />
                </div>
                <!-- 処分事業者 -->
                <div v-if="!isDisposer" class="c-infoList__cell md text-dotted">
                  {{ entrust.disposalCompanyInfo ? entrust.disposalCompanyInfo.name : "-"}}
                </div>
                <div class="c-infoList__cell sm">
                  <span v-if="entrust.contractEndLimit === limitStatus.DANGER">契約終了</span>
                  <span v-else-if="entrust.contractEndLimit === limitStatus.WARNING">有効（注意）</span>
                  <span v-else-if="entrust.isActive">有効</span>
                  <span v-else-if="!entrust.isActive">無効</span>
                </div>
                <div class="c-infoList__cell sm">
                  {{ entrust.activeStartDate | dateJpYMDddFormat }}
                </div>
              </div>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致する委託契約書はありません。
                </div>
              </div>
            </template>
          </div>
        </div>

        <Pagination v-if="entrustList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getContractSearchApi(searchedParam, true)"
        />
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import SearchForm from "./components/SearchForm.vue";
import CommaText from "@/components/parts/CommaText";
import search from "./mixins/search";
import Pagination from "@/components/parts/Pagination";
import ToastMessage from "@/components/parts/ToastMessage";
import moment from "moment";
import {DATE_LIMIT_STATUS, CONTRACT_END_ALERT_INTERVAL} from "@/const/dateLimit";
import { saveAs } from "file-saver";
import { API_URL } from "@/const";

export default {
  name: "entrust-all",
  components: {
    ToastMessage,
    SearchForm,
    Pagination,
    CommaText,
  },
  mixins: [search],
  data() {
    return {
      isCollector: this.$store.getters["userInfo/isCollector"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      isAllChecked: false,
      isInvalidBtnActive: false,
      isValidBtnActive: false,
      isDisableEntrustDelivery: true,
      checkCount: 0,
      entrustList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "generate_company_name",
        type: "asc",
      },
      errMsgs: [],
      toastMsg: "",
      limitStatus: DATE_LIMIT_STATUS,
    };
  },

  methods: {
    // チェックされて無効化なものを返す
    checkValidBtns() {
      let checkedEntrustList = [];
      let activeEntrusts = [];
      let notActiveEntrusts = [];
      checkedEntrustList = this.entrustList.filter(
        (entrust) => entrust.isChecked
      );
      checkedEntrustList.map((entrust) => {
        if (entrust.isActive) {
          activeEntrusts = [...activeEntrusts, entrust];
        } else {
          notActiveEntrusts = [...notActiveEntrusts, entrust];
        }
      });
      if (activeEntrusts.length) {
        this.isValidBtnActive = false;
      } else if (!checkedEntrustList.length) {
        this.isValidBtnActive = false;
      } else {
        this.isValidBtnActive = true;
      }

      if (notActiveEntrusts.length) {
        this.isInvalidBtnActive = false;
      } else if (!checkedEntrustList.length) {
        this.isInvalidBtnActive = false;
      } else {
        this.isInvalidBtnActive = true;
      }

      if (checkedEntrustList.length) {
        this.isDisableEntrustDelivery = false;
      } else {
        this.isDisableEntrustDelivery = true;
      }
      if (checkedEntrustList.length > 5) {
        this.isDisableEntrustDelivery = true;
      }
    },

    // マニフェスト全件チェックボックス
    changeAllCheckHandler() {
      if (this.isAllChecked) {
        this.entrustList.map((entrust) => {
          entrust.isChecked = true;
        });
      } else {
        this.entrustList.map((entrust) => {
          entrust.isChecked = false;
        });
      }
    },

    // 各マニフェストのチェックボックス
    changeEntrustCbHandler(isChecked) {
      if (!isChecked) {
        this.isAllChecked = false;
      }
    },

    // 排出事業者名ソート変更
    clickSortChangeHandler() {
      this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.getContractSearchApi(this.searchedParam, false);
    },

    getEntrustIds() {

      const checkedEntrusts = this.entrustList.filter(
        (entrust) => entrust.isChecked
      );
      const targetIdList = checkedEntrusts.map((entrust) => {
        return { id: entrust.entrustId };
      });
      return targetIdList;
    },

    // 委託契約の受渡確認票DL APIのパラメータ設定
    getCheckedEntrustIdsForDeliveryCheckSheet() {
      const checkedEntrusts = this.entrustList.filter(
        (entrust) => entrust.isChecked
      );
      const targetIdList = checkedEntrusts.map((entrust) => {
        return { entrustId: entrust.entrustId };
      });
      return targetIdList;
    },

    // 有効化・無効化押下
    clickUpdateEntrustHandler(isToValid) {
      this.errMsgs = [];
      const targetIdList = this.getEntrustIds();

      axios
        .patch(API_URL.ENTRUST.UPDATE, {
          isActive: isToValid,
          targetIdList: targetIdList,
        })
        .then(() => {
          // リロード
          this.getContractSearchApi(this.$store.getters["searchParamEntrust/get"], false);
          this.toastMsg = `${targetIdList.length}件の委託契約書を${isToValid ? "有効化" : "無効化"}しました。`;
        })
        .catch((err) => {
          this.errMsgs.push(err.message);
        });
    },

    // 契約終了日の期限
    checkContractEndLimit(isActive, contractEndDate) {
      if (!contractEndDate) return ""; // 本番運用では発生しないが、契約終了日がない場合スルー
      const today = moment();
      const targetDate = moment(contractEndDate);
      const diff = targetDate.diff(today, "days");
      if (isActive && diff < 0) {
        return DATE_LIMIT_STATUS.DANGER;
      } else if (isActive && diff <= CONTRACT_END_ALERT_INTERVAL && diff >= 0) {
        return DATE_LIMIT_STATUS.WARNING;
      } else return "";
    },
    setEntrustPage() {
      this.$store.commit("searchParamEntrust/setEntrustPage", this.paginationParams.page);
    },
    addRequestToLocation(requestObj) {
        const param = new URLSearchParams(requestObj)
        history.replaceState(
        {},
        null,
        this.$route.path + '?' + param
      )
    },

    // 受渡確認票をダウンロード
    clickDownloadHandler() {
      this.errMsgs = [];
      this.isDisableEntrustDelivery = true;
      axios
        .post(API_URL.ENTRUST.DELIVERY_CHECKSHEET
            , {entrustIdList: this.getCheckedEntrustIdsForDeliveryCheckSheet() }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
            saveAs(blob, `${moment().format("YYYYMMDD")}_受渡確認票.zip`);
        })
        .then(() => {
          // ダウンロードボタンは活生にする
          this.isDisableEntrustDelivery = false;
        })
        .catch(async (err) => {
          let responseObj = await new Response(err.response.data).json();
          this.errMsgs.push(responseObj.message);
        });
    },

  },

  watch: {
    entrustList: {
      handler: function () {
        let count = 0;
        this.checkValidBtns();
        this.entrustList.forEach((entrust) => {
          if (entrust.isChecked) {
            count++;
          }

          // 契約終了日チェック
          const limitList = [];
          entrust.msgs = [];
          // 運搬事業者
          entrust.collectCompanyList.forEach(collectCompany => {
            const collectLimit = this.checkContractEndLimit(entrust.isActive, collectCompany.contractEndDate);
            if (collectLimit === DATE_LIMIT_STATUS.DANGER) {
              const danger = `${collectCompany.name} との契約期限が過ぎています。`;
              entrust.msgs.push(danger);
            } else if (collectLimit === DATE_LIMIT_STATUS.WARNING) {
              let warning = `${collectCompany.name} との契約期限が迫っています。`;
              entrust.msgs.push(warning);
            }
            limitList.push(collectLimit);
          });
          // 処分事業者
          const disposalLimit = this.checkContractEndLimit(entrust.isActive, entrust.disposalCompanyInfo.contractEndDate);
          if (disposalLimit === DATE_LIMIT_STATUS.DANGER) {
            const danger = `${entrust.disposalCompanyInfo.name} との契約期限が過ぎています。`;
            entrust.msgs.push(danger);
          } else if (disposalLimit === DATE_LIMIT_STATUS.WARNING) {
            let warning = `${entrust.disposalCompanyInfo.name} との契約期限が迫っています。`;
            entrust.msgs.push(warning);
          }
          limitList.push(disposalLimit);
          
          if (limitList.includes(DATE_LIMIT_STATUS.DANGER)) {
            entrust.contractEndLimit = DATE_LIMIT_STATUS.DANGER;
          } else if (limitList.includes(DATE_LIMIT_STATUS.WARNING)) {
            entrust.contractEndLimit = DATE_LIMIT_STATUS.WARNING;
          }
        });

        this.checkCount = count;
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      if(from.path.includes("/entrust")) {
        let page = vm.$store.getters["searchParamEntrust/getEntrustPage"]
        vm.paginationParams.page = page
      } else {
        // リセットする
        vm.$store.commit("searchParamEntrust/setEntrustPage", 1);
      }
    })
  },
  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.status) {
      this.$store.commit("searchParamEntrust/set", {
        wasteName: "",
        generateCompanyId: "",
        generateFactoryId: "",
        generateStoreList: [],
        collectCompanyId: "",
        disposalCompanyId: "",
        licenceNo: "",
        contractSearchStatus: this.$route.query.status.split(",").map(s => Number(s)),
        isAutoUpdate: [],
        contractEndDateFrom: "",
        contractEndDateTo: "",
        wasteTypeIdList: [],
        jwnetItemTypeFieldList: [],
        manifestTypeIdList: [],
      });
    } else if (this.$route.query.licenceNo) {
      this.$store.commit("searchParamEntrust/set", {
        wasteName: "",
        generateCompanyId: "",
        generateFactoryId: "",
        generateStoreList: [],
        collectCompanyId: "",
        disposalCompanyId: "",
        licenceNo: this.$route.query.licenceNo,
        contractSearchStatus: [],
        isAutoUpdate: [],
        contractEndDateFrom: "",
        contractEndDateTo: "",
        wasteTypeIdList: [],
        jwnetItemTypeFieldList: [],
        manifestTypeIdList: [],
      });
    } else if (this.$route.query.disposalCompanyId) {
      this.$store.commit("searchParamEntrust/set", {
        wasteName: "",
        generateCompanyId: "",
        generateFactoryId: "",
        generateStoreList: [],
        collectCompanyId: "",
        disposalCompanyId: this.$route.query.disposalCompanyId,
        licenceNo: "",
        contractSearchStatus: [],
        isAutoUpdate: [],
        contractEndDateFrom: "",
        contractEndDateTo: "",
        wasteTypeIdList: [],
        jwnetItemTypeFieldList: [],
        manifestTypeIdList: [],
      });
    } else if (this.$route.query.collectCompanyId) {
      this.$store.commit("searchParamEntrust/set", {
        wasteName: "",
        generateCompanyId: "",
        generateFactoryId: "",
        generateStoreList: [],
        collectCompanyId: this.$route.query.collectCompanyId,
        disposalCompanyId: "",
        licenceNo: "",
        contractSearchStatus: [],
        isAutoUpdate: [],
        contractEndDateFrom: "",
        contractEndDateTo: "",
        wasteTypeIdList: [],
        jwnetItemTypeFieldList: [],
        manifestTypeIdList: [],
      });
    }
  },

  mounted() {
    this.getContractSearchApi(this.$store.getters["searchParamEntrust/get"], true);
  },
};
</script>
