import axios from "axios";
import { API_URL } from "@/const";

export default {
  data() {
    return {
      selectGenerateCompanyList: [], //入力テキストで絞る排出事業者選択肢
      selectGeneratorCollectList: [], //入力テキストで絞る運搬事業者選択肢
      selectGeneratorDisposalList: [], //入力テキストで絞る処分事業者選択肢
      selectDisposalSiteList: [], //入力テキストで絞る処分場選択肢
      generateCompanyList: [],
      generateFactoryIdList: [],
      storeListTemp: [],
      storeList: [],
      generatorCollectList: [],
      generatorDisposalList: [],
      disposalSiteList: [],
    };
  },
  methods: {

    // 排出事業所選択肢取得API:  API_URL.ENTRUST.SELECT_FACTORY + {generatecompanyid}
    getGenerateFactoryApi() {
      // 排出事業者IDを設定した場合、排出事業所リストを取得
      // 運搬の場合、委託契約にひもづくもののみを取得
      if (this.searchParam.generateCompanyId) {
        const URL = this.$store.getters["userInfo/isCollector"]
        ? API_URL.COLLECTOR.SELECT_FACTORY + this.searchParam.generateCompanyId : API_URL.ENTRUST.SELECT_FACTORY + this.searchParam.generateCompanyId;
        return axios
          .get(URL)
          .then((res) => {
            this.generateFactoryIdList = res.data.generateFactoryIdList ? res.data.generateFactoryIdList : res.data.generateFactoryList;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    // 事業場選択肢取得API
    getStoreListApi() {
      const URL = API_URL.COMMON.GENERATE_STORE_LIST
      return axios
        .get(URL)
        .then((res) => {
          this.storeList = res.data.generateStoreList;
          if (this.searchParam.generateCompanyId) {
            this.storeListTemp = this.storeList.filter(s => s.companyId == this.searchParam.generateCompanyId);
          }
          if (this.searchParam.generateStoreList) {
            this.generateStoreListTemp = [...this.searchParam.generateStoreList]
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 排出事業者選択肢取得API
    async getGenerateCompanyApi() {
      // CBA・排出アカウントの場合：排出事業者選択肢取得API： API_URL.ENTRUST.GENERATE_SELECT_COMPANY
      // 運搬アカウントの場合：運搬に紐づく排出事業者選択肢取得： API_URL.COLLECTOR.GENERATE_SELECT_COMPANY
      // 処分アカウントの場合：処分に紐づく排出事業者選択肢取得： API_URL.DISPOSER.GENERATE_SELECT_COMPANY
      var url = "";
      if (this.$store.getters["userInfo/isGenerator"] || this.$store.getters["userInfo/isCba"]) {
        url = API_URL.ENTRUST.GENERATE_SELECT_COMPANY;
      }
      if (this.$store.getters["userInfo/isCollector"]) {
        url = API_URL.COLLECTOR.GENERATE_SELECT_COMPANY
      }
      if (this.$store.getters["userInfo/isDisposer"]) {
        url = API_URL.DISPOSER.GENERATE_COMPANY_SELECT
      }
      await this.getGenerateCompanySelection(url).then((data) => {
        this.generateCompanyList = data;
        this.selectGenerateCompanyList = data;
        if (this.searchParam.generateCompanyId) {
          this.selectedGenerateCompany = this.generateCompanyList.find(e => e.id === this.searchParam.generateCompanyId)
        }
      }).catch(() => {})
    },

    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      // 運搬でログインする場合、運搬事業者選択肢は不要なので、APIを呼ばない
      if (this.$store.getters["userInfo/isCollector"]) return;

      // CBA・排出アカウントの場合：運搬事業者選択肢取得API： API_URL.MANIFEST.GENERATOR_COLLECT_LIST
      // 処分アカウントの場合：処分に紐づく運搬事業者選択肢取得： API_URL.DISPOSER.COLLECT_COMPANY_SELECT
      var url = "";
      if (this.$store.getters["userInfo/isGenerator"] || this.$store.getters["userInfo/isCba"]) {
        url = API_URL.MANIFEST.GENERATOR_COLLECT_LIST;
      }
      if (this.$store.getters["userInfo/isDisposer"]) {
        url = API_URL.DISPOSER.COLLECT_COMPANY_SELECT;
      }
      this.getCollectCompanySelectionForEntrust(url).then((data) => {
        this.generatorCollectList = data;
        this.selectGeneratorCollectList = data;
        if (this.searchParam.collectCompanyId) {
          this.selectedGeneratorCollect = this.generatorCollectList.find(e => e.id === this.searchParam.collectCompanyId)
        }
      }).catch(() => {})
    },

    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      const isAuthority = this.$store.getters["userInfo/isGenerator"] || this.$store.getters["userInfo/isCba"]
      if (isAuthority) {
        this.getGeneratorDisposalSelection().then((data) => {
          this.generatorDisposalList = data
          this.selectGeneratorDisposalList = data;
          if (this.searchParam.disposalCompanyId) {
            this.selectedGeneratorDisposal = this.generatorDisposalList.find(e => e.id === this.searchParam.disposalCompanyId)
          }        
        }).catch(() => {})
      }
    },

    // 処分事業場選択肢取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data
        this.selectDisposalSiteList = data;
        if (this.searchParam.disposalSiteId) {
          this.selectedDisposalSite = this.disposalSiteList.find(e => e.id === this.searchParam.disposalSiteId)
        }
      }).catch(() => {})
    },
  },
};
