<template>
  <div>
    <div class="c-searchMenu__wrap">
      <div v-if="isDisplaySearch" class="c-searchMenu">
        <div class="l-flex">
          <div v-if="shorten().length === 0">
            <div class="title icon">条件なし</div>
          </div>
          <template v-else v-for="(s, index) in shorten()">
            <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
              {{ s.key }}<div class="data"> : {{ s.value }}</div>
            </div>
          </template>
        </div>
        <div class="l-flex">
          <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
        </div>
      </div>
    </div>
    <div class="l-full-white l-area">
      <div class="l-box l-flex">
        <h3 class="c-lead-s-black icon">検索</h3>
        <div class="c-tabs l-area-l">
          <div
            class="c-tabs__each"
            :class="{ active: !isSearchDetail }"
            @click="isSearchDetail = false; initSearch(false);"
          >簡易</div>
          <div
            class="c-tabs__each"
            :class="{ active: isSearchDetail }"
            @click="isSearchDetail = true; initSearch(false);"
          >詳細</div>
        </div>
      </div>
      <div>
        <div class="c-inputWrap">
          <div>
            <span class="text-label">廃棄物名称</span>
            <InputText
              class="xlg"
              :value.sync="searchParam.wasteName"
              inputType="text"
              placeholder="廃棄物名称を入力（スペース区切りでOR検索）"
              :validation="validateMaxLen"
              :validateArg="100"
            >
            </InputText>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols center search-wrap">
            <div>
              <span class="text-label">排出事業者</span>
              <v-autocomplete ref="generate_company_autocomplete" @change="s => {onChange(s, 'generate_company_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGenerateCompanyList" :min-len="0" :wait="300" @update-items='updateGenerateCompanyList' v-model="selectedGenerateCompany" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
              </v-autocomplete>
            </div>
            <div>
              <span class="text-label">排出事業所</span>
              <Selectbox :class="{ disabled: !searchParam.generateCompanyId }"
                class="mid"
                :value.sync="searchParam.generateFactoryId"
                :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                  v-for="(opt, index) in generateFactoryIdList"
                  :key="`gf-opt-${index}`"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
            <div>
              <span class="text-label">排出事業場</span>
              <div class="l-flex cols">
                <span v-if="searchParam.generateStoreList.length">{{ searchParam.generateStoreList.map(s => s.name).join("、") }}</span>
                <div
                    class="c-btn secondary small"
                    :class="{ disabled: !searchParam.generateCompanyId }"
                    @click="selectGenerateStoreHandler"
                >
                  排出事業場選択
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 処分場 -->
        <div v-if="isCollector" class="c-inputWrap">
          <div>
            <span class="text-label">処分場</span>
            <v-autocomplete ref="disposal_site_autocomplete" @change="s => {onChange(s, 'disposal_site_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
            </v-autocomplete>
          </div>
        </div>
        <div class="c-inputWrap">
          <div>
            <span class="text-label">委託契約書番号</span>
            <InputText
              class="xlg"
              :value.sync="searchParam.entrustId"
              inputType="text"
              placeholder="委託契約書を入力（スペース区切りでOR検索）"
              :validation="validateMaxLen"
              :validateArg="100"
            >
            </InputText>
          </div>
        </div>

        <!-- 詳細のときだけ表示 -->
        <template v-if="isSearchDetail">
          <div v-if="!isCollector" class="c-inputWrap">
            <div class="c-inputWrap__items__cols center search-wrap">
              <div>
                <span class="text-label">運搬事業者</span>
                  <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGeneratorCollectList" :min-len="0" :wait="300" @update-items='updateGeneratorCollectList' v-model="selectedGeneratorCollect" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
              </div>
              <div v-if="!isDisposer">
                <span class="text-label">処分事業者</span>
                  <v-autocomplete ref="disposal_autocomplete" @change="s => {onChange(s, 'disposal_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectGeneratorDisposalList" :min-len="0" :wait="300" @update-items='updateGeneratorDisposalList' v-model="selectedGeneratorDisposal" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分場</span>
                  <v-autocomplete ref="disposal_site_autocomplete" @change="s => {onChange(s, 'disposal_site_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
                  </v-autocomplete>
              </div>
              <div>
                <span class="text-label">許可証番号</span>
                <InputText
                  :value.sync="searchParam.licenceNo"
                  inputType="text"
                  placeholder="許可証番号を入力"
                  :validation="validateJustBlank"
                >
                </InputText>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">ステータス</span>
                <div class="l-flex wrap full">
                  <div
                    v-for="(cb, index) in statusCb"
                    :key="'status-' + index"
                    class="c-input"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`cb-is-status-${index}`"
                        type="checkbox"
                        v-model="searchParam.contractSearchStatus"
                        :value="cb.value"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`cb-is-status-${index}`"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ cb.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span class="text-label">契約自動更新</span>
                <div class="l-flex wrap full">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                          id="is-auto-true"
                          type="checkbox"
                          v-model="searchParam.isAutoUpdate"
                          :value="true"
                      />
                      <label class="c-checkbox__label" for="is-auto-true">
                        <span class="c-checkbox__box"></span>
                        有
                      </label>
                    </div>
                  </div>
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                          id="is-auto-false"
                          type="checkbox"
                          v-model="searchParam.isAutoUpdate"
                          :value="false"
                      />
                      <label class="c-checkbox__label" for="is-auto-false">
                        <span class="c-checkbox__box"></span>
                        無
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <!--廃棄物区分区分条件-->
              <div>
                <span class="text-label">廃棄物区分</span>
                <div class="l-flex cols">
                  <div
                      class="c-input"
                      v-for="(wasteTypeId, wtIndex) in wasteTypeIdOpt"
                      :key="'wasteTypeId-' + wtIndex"
                  >
                    <div class="c-checkbox">
                      <input
                          :id="'wasteTypeId-' + wtIndex"
                          type="checkbox"
                          v-model="searchParam.wasteTypeIdList"
                          :value="wasteTypeId.value"
                      />
                      <label
                          class="c-checkbox__label"
                          :for="'wasteTypeId-' + wtIndex"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ wasteTypeId.label }}
                      </label>

                      <!--産業廃棄物区分の場合-->
                      <template
                          v-if="
                              wasteTypeId.value === wasteTypeIdOpt[0].value &&
                              searchParam.wasteTypeIdList.includes(
                                wasteTypeId.value
                              )
                            "
                      >
                        <div class="l-flex wrap full">
                          <span>(</span>
                          <div
                              class="c-input"
                              v-for="(
                                  industrialWasteType, industrialWasteTypeIndex
                                ) in industrialWasteJwnetTypeOpt"
                              :key="
                                  'industrialWasteType-' +
                                  industrialWasteTypeIndex
                                "
                          >
                            <input
                                :id="
                                    'industrialWasteType-' +
                                    industrialWasteTypeIndex
                                  "
                                type="checkbox"
                                v-model="
                                    searchParam.jwnetItemTypeFieldList
                                  "
                                :value="industrialWasteType.value"
                            />
                            <label
                                class="c-checkbox__label"
                                :for="
                                    'industrialWasteType-' +
                                    industrialWasteTypeIndex
                                  "
                            >
                              <span class="c-checkbox__box"></span>
                              {{ industrialWasteType.label }}
                            </label>
                          </div>
                          <span>)</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <!--マニフェスト区分条件-->
              <div>
                <span class="text-label">マニフェスト区分</span>
                <div class="l-flex cols">
                  <div
                      class="c-input"
                      v-for="(
                          manifestType, manifestTypeIndex
                        ) in manifestTypeOpt"
                      :key="'manifestType-' + manifestTypeIndex"
                  >
                    <div class="c-checkbox">
                      <input
                          :id="'manifestType-' + manifestTypeIndex"
                          type="checkbox"
                          v-model="searchParam.manifestTypeIdList"
                          :value="manifestType.value"
                      />
                      <label
                          class="c-checkbox__label"
                          :for="'manifestType-' + manifestTypeIndex"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ manifestType.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">契約終了日</span>
                <div class="c-inputWrap__items__cols search-wrap">
                  <InputDate
                    :value.sync="searchParam.contractEndDateFrom"
                    placeholder="0000/00/00"
                  />
                  <span>〜</span>
                  <InputDate
                    :value.sync="searchParam.contractEndDateTo"
                    placeholder="0000/00/00"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="l-container">
      <div class="l-btns l-block-lg">
        <div class="c-btn secondary small" @click="resetSearchHandler">
          条件をリセット
        </div>
        <div class="c-btn primary small" @click="searchHandler">検索</div>
      </div>
    </div>

    <!-- 排出事業場選択モーダル -->
    <div v-if="isGenerateCompanySelectShow" class="c-modal lg">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">排出事業場選択</h5>
          <span
            class="c-modal__head__close"
            @click="cancelGenerateFactoryModalHandler"
          ></span>
        </div>
        <div class="c-modal__body">
          <div>
            <div class="c-modal__body__originalList">
              <div class="c-checkbox" v-for="(sl, storeIndex) of storeListTemp" :key="'store-' + storeIndex">
                <input
                  :id="sl.store.id"
                  type="checkbox"
                  :name="'waste-type' + sl.store.id"
                  :value="sl.store"
                  v-model="generateStoreListTemp"
                />
                <label class="c-checkbox__label" :for="sl.store.id">
                  <span class="c-checkbox__box"></span>
                  {{ sl.store.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="cancelGenerateFactoryModalHandler"
          >
            キャンセル
          </div>
          <div class="c-btn primary small" @click="setGenerateFactoryHandler">
            設定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Selectbox from "@/components/parts/input/Selectbox";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import {DOMAIN} from "@/const";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";

export default {
  components: {
    InputText,
    InputDate,
    Selectbox,
  },
  mixins: [validation, callApi, selectionLocalStorage],
  data() {
    return {
      isFetching: true,
      selectedGenerateCompany: '', // 選択した排出事業者
      selectedGeneratorCollect : '', //選択した運搬事業者
      selectedGeneratorDisposal: '', //選択した処分事業者
      selectedDisposalSite: '', //選択した処分事業場
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate: SelectItemShowNameAndJwnetIdTemplate,
      isDisplaySearch: false,
      isSearchDetail: false,
      isGenerateCompanySelectShow: false,
      generateStoreListTemp: [], // モーダル内一時的
      // 検索v-model
      searchParam: {
        entrustId:"",
        wasteName: "",
        generateCompanyId: "",
        generateFactoryId: "",
        generateStoreList: [],
        collectCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        licenceNo: "",
        contractSearchStatus: [],
        isAutoUpdate: [],
        contractEndDateFrom: "",
        contractEndDateTo: "",
        wasteTypeIdList: [],
        jwnetItemTypeFieldList: [],
        manifestTypeIdList: [],
      },
      statusCb: [
        {
          label: "有効",
          value: DOMAIN.CONTRACT_SEARCH_STATUS.ACTIVE,
        },
        {
          label: "有効（注意）",
          value: DOMAIN.CONTRACT_SEARCH_STATUS.ALERT_ACTIVE,
        },
        {
          label: "無効",
          value: DOMAIN.CONTRACT_SEARCH_STATUS.INVALID,
        },
        {
          label: "契約終了",
          value: DOMAIN.CONTRACT_SEARCH_STATUS.CLOSE_INVALID,
        },
      ],
      wasteTypeIdOpt: [
        { value: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE , label: "産業廃棄物" },
        { value: DOMAIN.WASTE_TYPE.VALUABLES , label: "有価物" },
        { value: DOMAIN.WASTE_TYPE.COMMON_WASTE , label: "一般廃棄物" },
      ],
      industrialWasteJwnetTypeOpt: [
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_NOT_SPECIAL_INDUSTRIAL_WASTE , label: "普通産業廃棄物" },
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE , label: "特別管理産業廃棄物" },
      ],
      manifestTypeOpt: [
        { value: DOMAIN.MANIFEST_TYPE.DIGITAL, label: "電子" },
        { value: DOMAIN.MANIFEST_TYPE.ANALOG, label: "紙マニフェスト" },
      ],
      isCollector: this.$store.getters["userInfo/isCollector"],
      isCba: this.$store.getters["userInfo/isCba"],
      isInit: true,
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      isSelectBtnActive: false,
    };
  },

  methods: {
    onChange(queryString, ref) {
      this.$nextTick(() => {
          this.$refs[ref].searchText = queryString;
      });
    },
    initSearch(isAll = true) {
      this.selectedGenerateCompany = isAll ? "" : this.selectedGenerateCompany
      this.selectedGeneratorCollect = ''
      this.selectedGeneratorDisposal =''
      this.selectedDisposalSite = this.isCollector && !isAll ? this.selectedDisposalSite : ''
      this.searchParam = {
        entrustId: isAll ? "" : this.searchParam.entrustId,
        wasteName: isAll ? "" : this.searchParam.wasteName,
        generateCompanyId: isAll ? "" : this.searchParam.generateCompanyId,
        generateFactoryId: isAll ? "" : this.searchParam.generateFactoryId,
        generateStoreList: isAll ? [] : this.searchParam.generateStoreList,
        collectCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: this.isCollector && !isAll ? this.searchParam.disposalSiteId : '',
        licenceNo: "",
        contractSearchStatus: [],
        isAutoUpdate: [],
        contractEndDateFrom: "",
        contractEndDateTo: "",
        wasteTypeIdList: [],
        jwnetItemTypeFieldList:[],
        manifestTypeIdList: [],
      };
    },

    selectGenerateStoreHandler() {
      this.isGenerateCompanySelectShow = true;
      this.getGenerateFactoryApi();
    },

    //モーダル内キャンセル押下
    cancelGenerateFactoryModalHandler() {
      this.isGenerateCompanySelectShow = false;
      this.generateStoreListTemp = this.searchParam.generateStoreList;
    },

    // モーダル内設定押下
    setGenerateFactoryHandler() {
      this.searchParam.generateStoreList = this.generateStoreListTemp;
      this.isGenerateCompanySelectShow = false;
    },

    resetSearchHandler() {
      this.initSearch();
      // this.$emit("search", this.searchParam); 条件リセット後の自動検索をやめる
    },

    searchHandler() {
      this.$emit("search", this.searchParam);
    },

    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      const result = [];
      const prev = this.$store.getters["searchParamEntrust/get"];

      if (prev.wasteName) result.push({ key: "廃棄物名称", value: prev.wasteName });
      if (prev.generateCompanyId) result.push({ key: "排出事業者", value: this.generateCompanyList.filter(g => g.id === prev.generateCompanyId)[0].name });
      if (prev.generateFactoryId) result.push({ key: "排出事業所", value: this.generateFactoryIdList.filter(gf => gf.id === prev.generateFactoryId)[0].name });
      if (prev.generateStoreList.length) {
        result.push({
          key: "排出事業場",
          value: prev.generateStoreList.map(s => s.name).join("、")
        });
      }
      if (prev.collectCompanyId) result.push({ key: "運搬事業者", value: this.generatorCollectList.filter(c => c.id === prev.collectCompanyId)[0].name });
      if (prev.disposalCompanyId) result.push({ key: "処分事業者", value: this.generatorDisposalList.filter(d => d.id === prev.disposalCompanyId)[0].name });
      if (prev.disposalSiteId) result.push({ key: "処分場", value: this.disposalSiteList.filter(d => d.id === prev.disposalSiteId)[0].name });
      if (prev.licenceNo) result.push({ key: "許可証番号", value: prev.licenceNo });
      if (prev.contractSearchStatus.length) {
        result.push({
          key: "ステータス",
          value: this.statusCb
              .filter(scb => prev.contractSearchStatus.includes(scb.value))
              .map(scb => scb.label).join("、")
        });
      }
      if (prev.wasteTypeIdList.length) {
        result.push({
          key: "廃棄物区分",
          value: this.wasteTypeIdOpt
              .filter(w => prev.wasteTypeIdList.includes(w.value))
              .map(w => w.label).join("、")
        });
      }
      if (prev.manifestTypeIdList.length) {
        result.push({
          key: "マニフェスト区分",
          value: this.manifestTypeOpt
              .filter(m => prev.manifestTypeIdList.includes(m.value))
              .map(m => m.label).join("、")
        });
      }
      if (prev.isAutoUpdate.length === 1) result.push({ key: "契約自動更新", value: this.isAutoUpdate[0] ? "有" : "無" });
      if (prev.contractEndDateFrom || prev.contractEndDateTo) result.push({ key: "契約終了日", value: prev.contractEndDateFrom + "〜" + prev.contractEndDateTo });

      return result;
    },
    handleGenerateCompanyChange() {
      if (this.isInit) {
        this.isInit = false
      } else {
        // 排出事業所の選択肢をリセットする
        this.searchParam.generateFactoryId = ""
      }
      this.getGenerateFactoryApi()
    },
    updateGenerateCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての排出事業者の選択肢を表示する
        this.selectGenerateCompanyList = this.generateCompanyList
        return
      }
      this.selectGenerateCompanyList = this.generateCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorCollectList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectGeneratorCollectList = this.generatorCollectList
        return
      }
      this.selectGeneratorCollectList = this.generatorCollectList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorDisposalList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGeneratorDisposalList = this.generatorDisposalList
        return
      }
      this.selectGeneratorDisposalList = this.generatorDisposalList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalSiteList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業場の選択肢を表示する
        this.selectDisposalSiteList = this.disposalSiteList
        return
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    getLabelDisposalSite (item) {
      if (item) {
        return item.name + "  JWNETID:"+ item.jwnetId;
      }
      return ''
    },
  },
  watch: {
    selectedGenerateCompany: function(newVal) {
      this.searchParam.generateCompanyId = newVal ? newVal.id : ''
      // 検索条件をリセット
      if (!this.isFetching) {
        this.searchParam.generateFactoryId = "";
        this.searchParam.generateStoreList = [];
        this.generateStoreListTemp = [];
      }

      // 選択肢リセット
      this.getGenerateFactoryApi();
      this.storeListTemp = this.storeList.filter(s => s.companyId == this.searchParam.generateCompanyId);
    },
    selectedGeneratorCollect: function(newVal) {
      this.searchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorDisposal: function(newVal) {
      this.searchParam.disposalCompanyId = newVal ? newVal.id : ''
    },
    selectedDisposalSite: function(newVal) {
      this.searchParam.disposalSiteId = newVal ? newVal.id : ''
    },
    'searchParam.generateFactoryId': function(newVal){
      if (!this.isFetching) {
        this.generateStoreListTemp = [];
        this.searchParam.generateStoreList = [];
      }
      if (newVal) {
        this.storeListTemp = this.storeList.filter(s => s.factoryId == this.searchParam.generateFactoryId );
      } else {
        this.storeListTemp = this.storeList.filter(s => s.companyId == this.searchParam.generateCompanyId);
      }
    }
  },

  mounted() {
    this.searchParam = this.$store.getters["searchParamEntrust/get"];
    if (this.isCollector) {
      this.isSearchDetail = this.$store.getters["searchParamEntrust/isSearchDetailForCollector"];
    } else {
      this.isSearchDetail = this.$store.getters["searchParamEntrust/isSearchDetail"];
    }

    window.addEventListener("scroll", this.handleScroll);

    this.getGenerateCompanyApi()
    .then(() => {
      this.getGenerateFactoryApi()
    })
    .then(() => {
      this.getStoreListApi()
    })
    .finally(() => {
      this.isFetching = false
    });
    this.getGeneratorCollectApi();
    this.getGeneratorDisposalApi();
    this.getDisposalSiteApi();
  },
};
</script>
