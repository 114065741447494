import axios from "axios";
import { API_URL } from "@/const";

export default {
  methods: {
    // URL生成
    getSearchApiUrl() {
      const params = new URLSearchParams();
      params.append("page", this.paginationParams.page);
      params.append("pageSize", this.paginationParams.pageSize);
      params.append("sort", `${this.sortParams.column}:${this.sortParams.type}`);
      if (this.searchedParam.entrustId) this.searchedParam.entrustId.trim().split(/\s+/).map((key) => {
        params.append("entrustId", parseInt(key));
      })
      if (this.searchedParam.wasteName) this.searchedParam.wasteName.trim().split(/\s+/).map((key) => {
        params.append("wasteName", key);
      })
      if (this.searchedParam.generateCompanyId) params.append("generateCompanyId", this.searchedParam.generateCompanyId);
      if (this.searchedParam.generateFactoryId) params.append("generateFactoryId", this.searchedParam.generateFactoryId);
      if (this.searchedParam.collectCompanyId) params.append("collectCompanyId", this.searchedParam.collectCompanyId);
      if (this.searchedParam.disposalCompanyId) params.append("disposalCompanyId", this.searchedParam.disposalCompanyId);
      if (this.searchedParam.disposalSiteId) params.append("disposalSiteId", this.searchedParam.disposalSiteId);
      if (this.searchedParam.licenceNo) params.append("licenceNo", this.searchedParam.licenceNo);
      if (this.searchedParam.contractEndDateFrom) params.append("contractEndDateFrom", this.searchedParam.contractEndDateFrom);
      if (this.searchedParam.contractEndDateTo) params.append("contractEndDateTo", this.searchedParam.contractEndDateTo);
      if (this.searchedParam.generateStoreList) {
        this.searchedParam.generateStoreList.forEach(store => {
          params.append("generateStoreList", store.id);
        });
      }
      if (this.searchedParam.contractSearchStatus) {
        this.searchedParam.contractSearchStatus.forEach((s) => {
          params.append("contractSearchStatus", s)
        });
      }
      if (this.searchedParam.wasteTypeIdList) {
        this.searchedParam.wasteTypeIdList.forEach((w) => {
          params.append("wasteTypeIdList", w)
        });
      }
      if (this.searchedParam.jwnetItemTypeFieldList) {
        this.searchedParam.jwnetItemTypeFieldList.forEach((j) => {
          params.append("jwnetItemTypeFieldList", j)
        });
      }
      if (this.searchedParam.manifestTypeIdList) {
        this.searchedParam.manifestTypeIdList.forEach((m) => {
          params.append("manifestTypeIdList", m)
        });
      }
      if (this.searchedParam.isAutoUpdate) {
        // [] or [true] or [false] or [true, false] の配列になる可能性がある
        // [] と [true, false] は検索条件に指定しないことと同義なので、[true] or [false] の場合のみ検索条件に追加
        if (this.searchedParam.isAutoUpdate.length === 1) {
          params.append("isAutoUpdate", this.searchedParam.isAutoUpdate[0]);
        }
      }
      return API_URL.ENTRUST.SEARCH + '?' + params.toString();
    },

    // 事業者契約を取得
    getContractSearchApi(searchParam, isPaging) {
      this.isAllChecked = false;
      this.searchedParam = JSON.parse(JSON.stringify(searchParam)); // deep copy
      if (!isPaging) {
        this.paginationParams.page = 1; // 1ページ目に初期化
      }
      axios
        .get(this.getSearchApiUrl())
        .then((res) => {
          this.entrustList = res.data.entrustList;
          this.paginationParams.total = res.data.searchParams.pagination.total;
          this.sortParams = res.data.searchParams.sorts[0];
          this.$store.commit("searchParamEntrust/set", this.searchedParam);
          const currentParam = this.$route.query
          const requestObj = { 'page' : parseInt(this.paginationParams.page) }
          this.addRequestToLocation(Object.assign(currentParam, requestObj))
        })
        .then(() => {
          this.entrustList.map((entrust) => {
            this.$set(entrust, "isChecked", false);
          });
        })
        .catch((err) => {
          this.entrustList = [];
          console.log(err);
        });
    },
  },
};
